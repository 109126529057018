@tailwind components;

@layer components {
  .alert-title{
    @apply text-primary
  }
}

.justify-added{
  justify-content: center;
}