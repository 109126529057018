@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .rm-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .overflowed {
    overflow: auto;
  }

  .overflowed::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: transparent;
  }

  .overflowed::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #ccc;
  }
}

@layer components {
  .MuiPaper-elevation.MuiDrawer-paper {
    @apply overflowed;
  }
  .fm-group {
    @apply flex flex-col space-y-1 py-2;
  }
  .fm-group-label {
    @apply text-xs font-semibold;
  }
  .fm-group-input {
    @apply py-3 px-6 rounded-full text-sm border-[#c5c5c5] dark:border-opacity-30 bg-transparent border w-full outline-none focus:border-gray-800 dark:focus:border-white dark:autofill:bg-dark placeholder:text-[#BCBCBC] dark:placeholder:text-[#8B8B8C] transition-all duration-300;
  }
  input,
  select {
    @apply dark:[color-scheme:dark];
  }
  .dialog-title {
    @apply text-center text-2xl font-semibold md:mt-5 max-w-md mx-auto;
  }
  .dialog-sub {
    @apply text-center text-lg font-semibold mt-2 max-w-sm mx-auto text-[#BCBCBC] dark:text-[#ffffff4d];
  }
}
.dark .fm-group-input:-webkit-autofill,
.dark .fm-group-input:-webkit-autofill:hover {
  border: 1px solid #848484;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #212223 inset;
}
.dark .fm-group-input:-webkit-autofill:focus {
  border: 1px solid white;
  caret-color: white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #212223 inset;
}

.swal2-container {
  z-index: 2000 !important;
  padding: 1rem !important;
}

.swal2-popup {
  border-radius: 1rem !important;
  max-width: 30rem !important;
  /* min-width: 15rem ; */
  width: unset !important;
  padding: 1rem !important;
}
.dark .swal2-popup {
  background-color: #171819;
}
.swal2-styled.swal2-confirm {
  border-radius: 0.5rem !important;
  background-color: #171819 !important;
  width: 100% !important;
  max-width: 17rem !important;
  box-shadow: none !important;
}
.dark .swal2-styled.swal2-confirm {
  background-color: #fff !important;
  color: black;
}
.dark .swal2-title {
  color: #fff !important;
}
.swal2-actions {
  width: 100% !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #cb1c27 !important ;
}
.swal2-html-container {
  margin: 0.5rem 0 !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #fff8f4 !important;
}
.dark .swal2-icon.swal2-success .swal2-success-ring {
  border-color: #fff8f405 !important;
}

/* Custom arrow */
.dark select.appearance-none {
  background-image: url("./assets/selectArror.svg") !important;
}
select.appearance-none {
  background-image: url("./assets/selectArrowBlack.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 1.3rem) center;
}
.swal-reg-success-icon {
  height: fit-content !important;
  border: none !important;
}
